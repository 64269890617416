import request from 'axios';
import {message} from "ant-design-vue";

const axios = request.create({
    baseURL: 'http://47.105.105.74/detection',
    // baseURL: 'http://127.0.0.1:8080/detection',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 6000
})

// 异常处理
const errorHandler = (error) => {
    // error.response.data
    message.error(error.toString(), 10)
    return Promise.reject(error)
}

// 请求拦截器
axios.interceptors.request.use((config) => {
    config.headers.common['token'] = localStorage.getItem('token') || '';
    return config;
}, errorHandler);

// 响应拦截器
axios.interceptors.response.use((response) => {
    let res = {};
    try {
        res = response.data
        switch (res.code) {
            case 401:  //未认证
                localStorage.clear()
                location.href = '/login'
                break
            case 600:
                message.error(res.msg)
                break
            default:
        }
    } catch (e) {
        res = response;
    }
    return res;
}, errorHandler);

export default axios;
