<template>
  <div class="index">
    <iframe
      style="width: 100%; height: 100%; border: none"
      src=""
    />
    <a-icon type="fullscreen" class="icon" @click="fullScreen" />
  </div>
</template>

<script>
import axios from "@/utils/axios";
export default {
  name: "Index",
  data() {
    return {};
  },
  methods: {
    fullScreen() {
      document.getElementsByTagName("iframe")[0].webkitRequestFullScreen();
    },
  },
  mounted() {
    axios.post("/checktoken").then((res) => {
      console.log(res);
    });
  },
};
</script>

<style scoped>
.index {
  width: calc(100vw - 304px);
  height: calc(100vh - 178px);
  position: relative;
}
.icon {
  position: absolute;
  top: 8px;
  right: 40px;
  font-size: 26px;
  z-index: 90000;
  cursor: pointer;
}
</style>
