<template>
  <a-locale-provider :locale="zhCN">
    <div id="app">
      <router-view />
    </div>
  </a-locale-provider>
</template>

<script>
import { mapState } from "vuex";
import zhCN from "ant-design-vue/es/locale-provider/zh_CN";

export default {
  data() {
    return { zhCN };
  },
  computed: mapState({
    lang: (state) => state.app.lang,
  }),
};
</script>

<style lang="stylus">
@import 'assets/css/index.css';
</style>
