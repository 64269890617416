import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import NProgress from 'nprogress'
import 'ant-design-vue/dist/antd.css'

Vue.config.productionTip = false;

/*全局路由拦截*/
router.beforeEach((to, from, next) => {
    NProgress.start()
    const token = localStorage.getItem('token') || '';
    if (!token) {
        localStorage.removeItem('token');
        if (to.path === '/login') {
            next();
        } else {
            next({ path: '/login' });
        }
    } else {
        // 存在token
        if (to.path === '/login') {
            next({ path: '/index' }); // 去首页
        } else {
            next();
        }
    }
});

router.afterEach(() => {
    NProgress.done()
})

Vue.use(Antd);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
