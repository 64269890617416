import Vue from 'vue';
import Router from 'vue-router';
import Index from "./views/index/Index";
import Base from "./views/Base";

const Login = () => import('./views/login/Login')

/*处理相同路由 再次进入报错*/
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
};

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/',
            name: 'base',
            component: Base,
            redirect: '/index',
            children: [
                {
                    path: 'index',
                    name: 'index',
                    component: Index
                },
                {
                    path: 'site',
                    name: 'site',
                    component: () => import(/* webpackChunkName: "site" */'./views/site/Site')
                },
                {
                    path: 'sample',
                    name: 'sample',
                    component: () => import(/* webpackChunkName: "sample" */'./views/sample/Sample')
                },
                {
                    path: 'subject',
                    name: 'subject',
                    component: () => import(/* webpackChunkName: "subject" */'./views/subject/Subject')
                },
                {
                    path: 'sampleadd',
                    name: 'sampleadd',
                    component: () => import(/* webpackChunkName: "sampleadd" */'./views/sampleadd/SampleAdd')
                },
                {
                    path: 'sampledata',
                    name: 'sampledata',
                    component: () => import(/* webpackChunkName: "sampledata" */'./views/sampledata/SampleData')
                },
                {
                    path: 'print',
                    name: 'print',
                    component: () => import(/* webpackChunkName: "print" */'./views/print/Print')
                },
                {
                    path: 'report',
                    name: 'report',
                    component: () => import(/* webpackChunkName: "report" */'./views/report/Report')
                }
            ]
        },
        {
            path: '*',
            redirect: '/site'
        }
    ]
})
