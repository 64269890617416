<template>
  <div class="base">
    <a-layout>
      <!--侧边导航栏-->
      <a-layout-sider
        :collapsed="collapsed"
        breakpoint="md"
        collapsedWidth="80"
        width="256"
        @breakpoint="isCollapsed"
        style="background-color: #fff"
      >
        <div class="logo">
          <img alt="" src="@/assets/img/logo.svg" />
          <h1 v-show="!collapsed" class="text">检测监管系统</h1>
        </div>
        <a-menu
          :defaultOpenKeys="['baseinfo']"
          :defaultSelectedKeys="[selectKey]"
          :selectedKeys="[selectKey]"
          :inlineCollapsed="collapsed"
          class="slide-nav"
          mode="inline"
          theme="light"
          @click="changeRoute"
        >
          <a-menu-item key="index">
            <a-icon type="pie-chart" />
            <span>首页</span>
          </a-menu-item>

          <a-sub-menu key="baseinfo">
            <span slot="title"
              ><a-icon type="home" /><span>基础信息</span></span
            >
            <a-menu-item key="site">站点设置</a-menu-item>
            <a-menu-item key="subject">被检单位</a-menu-item>
            <a-menu-item key="sample">产品信息</a-menu-item>
          </a-sub-menu>

          <a-sub-menu key="sub2">
            <span slot="title"
              ><a-icon type="appstore" /><span>检测管理</span></span
            >
            <a-menu-item key="sampleadd">样本录入</a-menu-item>
            <a-menu-item key="sampledata">数据采集</a-menu-item>
          </a-sub-menu>

          <a-sub-menu key="sub3">
            <span slot="title"
              ><a-icon type="printer" /><span>证码打印</span></span
            >
            <a-menu-item key="print">合格证打印</a-menu-item>
          </a-sub-menu>

          <a-sub-menu key="sub4">
            <span slot="title"
              ><a-icon type="pie-chart" /><span>数据统计</span></span
            >
            <a-menu-item key="report">数据报表</a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <!--头部-->
        <a-layout-header class="base-header">
          <a-button type="primary" @click="toggleCollapsed">
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
          </a-button>
          <!--右侧 信息-->
          <div class="system-info">
            <a-dropdown placement="bottomRight">
              <a class="user-info">
                <img alt="" class="avatar" src="@/assets/img/avatar.png" />
                <div>{{ username }}</div>
              </a>
              <a-menu slot="overlay" @click="handleSystemClick">
                <a-menu-divider />
                <a-menu-item key="logout">退出登录</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-layout-header>
        <!--内容区-->
        <a-layout-content class="base-content">
          <router-view />
        </a-layout-content>
        <!--底部-->
        <a-layout-footer>
          <div class="footer">
            Copyright
            <div class="copyright-icon">
              <a-icon type="copyright" />
            </div>
            2020 - {{ copyright }} &nbsp;
          </div>
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Base",
  data() {
    return {
      collapsed: false, //是否收起
      selectKey: "index", // 选中的菜单
      copyright: moment().format("YYYY"),
      username: "",
    };
  },
  methods: {
    /*切换nav 收起  展开状态*/
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    /*nav触发 收起*/
    isCollapsed(broken) {
      this.collapsed = broken;
    },
    /*右上角点击事件*/
    handleSystemClick({ key }) {
      switch (key) {
        case "logout": //退出登录
          localStorage.clear();
          location.href = "/login";
          break;
        default:
          break;
      }
    },
    /*路由切换*/
    changeRoute(e) {
      switch (e.key) {
        case "index": //首页
          this.$router.replace({ path: "/index" });
          break;
        case "site":
          this.$router.replace({ path: "/site" });
          break;
        case "sample":
          this.$router.replace({ path: "/sample" });
          break;
        case "subject":
          this.$router.replace({ path: "/subject" });
          break;
        case "sampleadd":
          this.$router.replace({ path: "/sampleadd" });
          break;
        case "sampledata":
          this.$router.replace({ path: "/sampledata" });
          break;
        case "print":
          this.$router.replace({ path: "/print" });
          break;
        case "report":
          this.$router.replace({ path: "/report" });
          break;
      }
    },
  },
  mounted() {
    this.username = localStorage.getItem("username");
  },
  watch: {
    $route(v) {
      this.selectKey = v.name;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './base.styl';
</style>
